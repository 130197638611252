import React, { useEffect, useState } from "react";
import { ColorPicker, DatePicker, InputNumber, Modal, Select } from "antd";
import { Button, Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import { isAdmin } from "../../../utils/permissions";
import { useAuth } from "../../../utils/useAuth";
import { useSelector } from "react-redux";

type Props = {
  isOpen: boolean;
  onCloseModal: () => void;
  onAddUser: (data: any) => void;
};

const { Option } = Select;

const AddUserModal: React.FC<Props> = ({ isOpen, onCloseModal, onAddUser }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [color, setColor] = useState<any>("#ff0000");
  const authUser: any = useAuth();
  const workingPatterns = useSelector(
    (state: any) => state.workingPattern.contracts
  );

  const handleOk = () => {
    onCloseModal();
  };
  const handleCancel = () => {
    onCloseModal();
  };

  const onFinish = async (values: any) => {
    if (color) {
      onAddUser({ ...values, color: color });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error("Failed:", errorInfo);
  };

  const onWorkingPatternChange = (data: any, options: any) => {
    console.log("data", options);
  };

  return (
    <Modal
      title={t("Add Employee")}
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[]}
    >
      <Form
        name="basic"
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        //   initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        {/* <Form.Item
          label={t("Username")}
          name="username"
          rules={[{ required: false, message: t("Username Required") }]}
        >
          <Input />
        </Form.Item> */}
        <Form.Item
          label={t("First Name")}
          name="firstName"
          rules={[{ required: true, message: t("First Name Required") }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("Last Name")}
          name="lastName"
          rules={[{ required: true, message: t("Last Name Required") }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true, type: "email" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("Password")}
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password />
        </Form.Item>
        {isAdmin(authUser?.roles) && (
          <Form.Item
            name="workingPatternId"
            label={t("Working Pattern")}
            rules={[{ required: true, message: "Please select position!" }]}
          >
            <Select
              placeholder={t("Select working pattern type")}
              onChange={onWorkingPatternChange}
              //   allowClear
              value={""}
            >
              {workingPatterns?.map((item: any, index: number) => {
                return (
                  <Option value={item._id} key={index}>
                    {item.contractName}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          label={t("Earnings In Percentage")}
          name="amount"
          rules={[{ required: false, message: t("Last Name Required") }]}
        >
          <InputNumber defaultValue={30} min={0} max={100} />
        </Form.Item>
        <Form.Item
          name="role"
          label={t("Role")}
          rules={[{ required: true, message: "Please select role!" }]}
        >
          <Select placeholder={t("Select role")} value={""}>
            <Option value={"user"} key={"user"}>
              {t("Employee")}
            </Option>
            <Option value={"moderator"} key={"moderator"}>
              {t("Moderator")}
            </Option>
            <Option value={"admin"} key={"admin"}>
              {t("Admin")}
            </Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="position"
          label={t("Position Type")}
          rules={[{ required: true, message: "Please select position!" }]}
        >
          <Select
            placeholder={t("Select position type")}
            // onChange={onGenderChange}
            //   allowClear
            value={""}
          >
            <Option value={"DOCTOR"} key={"doctor"}>
              {t("Doctor")}
            </Option>
            <Option value={"NURSE"} key={"nurse"}>
              {t("Nurse")}
            </Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="gender"
          label={t("Gender")}
          rules={[{ required: true, message: "Please select" }]}
        >
          <Select
            placeholder={t("Please select")}
            // onChange={onGenderChange}
            //   allowClear
            value={""}
          >
            <Option value={"male"} key={"male"}>
              {t("Male")}
            </Option>
            <Option value={"female"} key={"female"}>
              {t("Female")}
            </Option>
          </Select>
        </Form.Item>
        <Form.Item name="profileColor" label={t("Profile Color")}>
          <ColorPicker
            defaultValue={color}
            onChange={(value, hexColor) => setColor(hexColor)}
          />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            {t("Save")}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddUserModal;
