import axios from "axios";
import React, { useEffect, useState } from "react";
import type { ColumnsType } from "antd/es/table";
import {
  Button,
  Col,
  Collapse,
  Divider,
  Dropdown,
  Empty,
  Menu,
  MenuProps,
  Popconfirm,
  Row,
  Space,
  Table,
  Tabs,
  TabsProps,
  message,
} from "antd";
import AddApointmentModal from "../../Components/Modals/AddApointmentModal/AddApointmentModal";
import { format, isAfter, isDate, isToday, isValid, parseISO } from "date-fns";
import "./Appointments.scss";
import { BASE_API_URL } from "../../utils/consts";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as CalendarIcon } from "../../assets/images/icons/calendar.svg";
import appointmentBg from "../../assets/images/icons/appointments.svg";
import {
  MoreOutlined,
  DeleteOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import { setLoader } from "../../utils/redux/slices/loaderSlice";
import { useAuth } from "../../utils/useAuth";
type Props = {};

interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
}

const Appointments: React.FC<Props> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [appointments, setAppointments] = useState<any>(undefined);
  //   const [patients, setPatients] = useState<any>(undefined);
  const patients = useSelector((state: any) => state.patients.patients);
  const [staffMembers, setStaffMembers] = useState<any>(undefined);
  const userData = useSelector((state: any) => state.userData.user);
  const [appointmentsByDate, setAppoinmentsByDate] = useState<any>(undefined);
  const [activeAppointment, setActiveAppointment] = useState<any>(undefined);
  const authUser: any = useAuth();
  const [showAppointmentModal, setShowAppointmentModal] =
    useState<boolean>(false);

  useEffect(() => {
    if (userData.clientId) {
      dispatch(setLoader(true));
      axios
        .get(`${BASE_API_URL}/${userData.clientId}/appointments`)
        .then((res) => {
          setAppointments(res.data);
        })
        .catch((err) => console.error(err))
        .finally(() => dispatch(setLoader(false)));
    }
  }, [userData]);

  useEffect(() => {
    if (userData.clientId) {
      axios
        .get(`${BASE_API_URL}/${userData.clientId}/users`)
        .then((res) => {
          setStaffMembers(res.data);
        })
        .catch((err) => console.error(err));
    }
  }, [userData]);

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0]; // Get today's date in 'YYYY-MM-DD' format

    const todayAppointments: any = [];
    const upcomingAppointments: any = [];
    const pastAppointments: any = [];

    appointments?.forEach((appointment: any) => {
      const appointmentDate = parseISO(appointment.date);

      if (isToday(appointmentDate)) {
        todayAppointments.push(appointment);
      } else if (isAfter(appointmentDate, today)) {
        upcomingAppointments.push(appointment);
      } else {
        pastAppointments.push(appointment);
      }
    });
    setAppoinmentsByDate({
      todayAppointments,
      upcomingAppointments,
      pastAppointments,
    });
  }, [appointments]);

  const deleteItem = (row: any) => {
    if (userData.clientId) {
      dispatch(setLoader(true));
      axios.delete(`${BASE_API_URL}/appointment/${row._id}`).then((res) => {
        axios
          .get(`${BASE_API_URL}/${userData.clientId}/appointments`)
          .then((res) => {
            setAppointments(res.data);
          })
          .catch((error) => console.error(error))
          .finally(() => dispatch(setLoader(false)));
      });
    }
  };

  const confirm = (row: any) => {
    message.success(t("Appointment deleted"));
    deleteItem(row);
  };

  const isValidDateFormat = (input: string): boolean => {
    // Check if the input matches the "2:30" format
    const invalidFormatRegex: RegExp = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;

    if (invalidFormatRegex.test(input)) {
      return false;
    }

    // Try parsing the input as a date using date-fns
    const parsedDate = parseISO(input);

    // Check if the parsed date is valid
    return isValid(parsedDate);
  };
  const cancel = (e: React.MouseEvent<HTMLElement>) => {};
  const columns: ColumnsType<DataType> = [
    {
      title: t("Date"),
      dataIndex: "date",
      key: "date",
      render: (value) => {
        return <b>{format(new Date(value), "d-MMM-yy")}</b>;
      },
    },
    {
      title: t("Time"),
      dataIndex: "startTime",
      key: "startTime",
      render: (value, row: any) => {
        console.log("value", value, row);
        return (
          <b>
            {isValidDateFormat(value) ? format(new Date(value), "HH-mm") : ""} -{" "}
            {isValidDateFormat(row.endTime)
              ? format(new Date(row.endTime), "HH-mm")
              : ""}
          </b>
        );
      },
    },
    {
      title: t("Patient"),
      dataIndex: "patientId",
      key: "patient",
      render: (value) => value?.name,
      responsive: ["md"],
    },
    {
      title: t("Short Description"),
      dataIndex: "injury",
      key: "injury",
      responsive: ["md"],
    },

    {
      title: "Email",
      dataIndex: "patientId",
      key: "email",
      render: (value) => value?.email,
      responsive: ["md"],
    },
    {
      title: t("Phone Number"),
      dataIndex: "patientId",
      key: "phoneNo",
      render: (row) => row?.phoneNo,
    },
    {
      title: t("Doctor"),
      dataIndex: "userId",
      key: "user",
      render: (row) => (
        <div>
          <span className="userColor" style={{ background: row?.color }}></span>
          {row?.username}
        </div>
      ),
    },
    {
      title: "",
      key: "action",
      render: (row, record) => (
        <Popconfirm
          title={t("Delete the appointment")}
          description={t("Are you sure to delete this appointment?")}
          onConfirm={() => confirm(row)}
          onCancel={() => cancel}
          okText={t("Yes")}
          cancelText={t("No")}
        >
          <Button>Delete</Button>
        </Popconfirm>
      ),
    },
  ];
  const showModal = () => {
    setShowAppointmentModal(!showAppointmentModal);
    if (userData) {
      dispatch(setLoader(true));
      axios
        .get(`${BASE_API_URL}/${userData.clientId}/appointments`)
        .then((res) => {
          setAppointments(res.data);
        })
        .catch((err) => console.error(err))
        .finally(() => dispatch(setLoader(false)));
    }
  };

  const items: MenuProps["items"] = [
    {
      label: <div>Delete</div>,
      key: "0",
    },
  ];

  //   const genExtra = (item: any) => {
  // 	setActiveItem(item)
  // 	return (
  // 		<Dropdown menu={{ items }} trigger={["click"]}>
  // 		<a onClick={(e) => e.preventDefault()}>
  // 		  <Space>
  // 			<MoreOutlined />
  // 		  </Space>
  // 		</a>
  // 	  </Dropdown>
  // 	)
  //   }

  const handleMenuClick = ({ key, item }: any) => {
    console.log("Menu item clicked:", key);
    // Perform actions based on the menu item clicked
    // For example, you can have different actions for different menu items
  };
  const menu = (item: any) => (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="option1">
        <Popconfirm
          title={t("Delete the appointment")}
          description={t("Are you sure to delete this appointment?")}
          onConfirm={(e: any) => {
            e.stopPropagation();
            confirm(item);
          }}
          onCancel={(e: any) => {
            e.stopPropagation();
            // cancel()
          }}
          okText={t("Yes")}
          cancelText={t("No")}
        >
          <span onClick={(e) => e.stopPropagation()}>{t("Delete")}</span>
        </Popconfirm>
      </Menu.Item>
      <Menu.Item key="option2">
        <span onClick={(e) => e.stopPropagation()}>{t("Update")}</span>
      </Menu.Item>
    </Menu>
  );

  const onChange = (key: string) => {
    console.log(key);
  };

  console.log("appointmentsByDate", appointmentsByDate);
  const tabItems: TabsProps["items"] = [
    {
      key: "1",
      label: t("Today"),
      children: (
        <Row>
          <Col xs={24}>
            {/* <div className="titielAppointment">{t("Today")}</div> */}
            {appointmentsByDate?.todayAppointments?.length ? (
              <Collapse
                className="appointmentTodayList"
                collapsible="header"
                items={appointmentsByDate?.todayAppointments?.map(
                  (item: any, index: number) => {
                    return {
                      key: index,
                      label: (
                        <div className="appointmentItem">
                          <div className="date">
                            <div className="info">
                              <span
                                className="doctorColor"
                                style={{
                                  background: item.userId.color ?? "#000",
                                }}
                              ></span>
                              {item.userId.firstName}
                            </div>
                            <label>
                              <CalendarIcon />
                              {format(new Date(item.date), "d-MMM-yy")}{" "}
                              <b>
                                ({format(new Date(item.startTime), "HH-mm")} -{" "}
                                {format(new Date(item.endTime), "HH-mm")})
                              </b>
                            </label>

                            <div className="info">{item.patientId?.name}</div>
                          </div>
                          <div className="rightItem">
                            <Dropdown overlay={menu(item)} trigger={["click"]}>
                              <Button onClick={(e) => e.stopPropagation()}>
                                <MoreOutlined />
                              </Button>
                            </Dropdown>
                          </div>
                        </div>
                      ),
                      children: (
                        <Row
                          className="appointmentItemBody"
                          justify={"space-between"}
                        >
                          <Col>
                            {t("Email")}
                            <br /> <b>{item.patientId?.email}</b>
                          </Col>
                          <Col className="info">
                            {t("Phone Number")} <br />{" "}
                            <b>{item.patientId?.phoneNo}</b>
                          </Col>
                          <Col>
                            <div>{t("Notes")}</div> <br />
                            <b>{item?.injury}</b>
                          </Col>
                        </Row>
                      ),
                    };
                  }
                )}
              />
            ) : (
              <div className="emptyData">
                <Empty description={<>{t("No Data")}</>} />
              </div>
            )}
          </Col>
        </Row>
      ),
    },
    {
      key: "2",
      label: t("Upcoming"),
      children: (
        <Row>
          <Col xs={24}>
            {/* <div className="titielAppointment">{t("Upcoming")}</div> */}
            {appointmentsByDate?.upcomingAppointments?.length ? (
              <Collapse
                className="appointmentTodayList"
                collapsible="header"
                items={appointmentsByDate?.upcomingAppointments?.map(
                  (item: any, index: number) => {
                    return {
                      key: index,
                      label: (
                        <div className="appointmentItem">
                          <div className="date">
                            <div className="info">
                              <span
                                className="doctorColor"
                                style={{
                                  background: item.userId.color ?? "#000",
                                }}
                              ></span>
                              {item.userId.firstName}
                            </div>
                            <label>
                              <CalendarIcon />
                              {format(new Date(item.date), "d-MMM-yy")}{" "}
                              <b>
                                ({format(new Date(item.startTime), "HH-mm")} -{" "}
                                {format(new Date(item.endTime), "HH-mm")})
                              </b>
                            </label>

                            <div className="info">{item.patientId?.name}</div>
                          </div>
                          <div className="rightItem">
                            <Dropdown overlay={menu(item)} trigger={["click"]}>
                              <Button onClick={(e) => e.stopPropagation()}>
                                <MoreOutlined />
                              </Button>
                            </Dropdown>
                          </div>
                        </div>
                      ),
                      children: (
                        <Row
                          className="appointmentItemBody"
                          justify={"space-between"}
                        >
                          <Col>
                            {t("Email")} <b>{item.patientId?.email}</b>
                          </Col>
                          <Col className="info">
                            {t("Phone Number")} <b>{item.patientId?.phoneNo}</b>
                          </Col>
                          <Col>
                            <div>{t("Notes")}</div>
                            <b>{item?.injury}</b>
                          </Col>
                        </Row>
                      ),
                    };
                  }
                )}
              />
            ) : (
              <div className="emptyData">
                <Empty description={<>{t("No Data")}</>} />
              </div>
            )}
          </Col>
        </Row>
      ),
    },
    {
      key: "3",
      label: t("Past"),
      children: (
        <Row>
          <Col xs={24}>
            {/* <div className="titielAppointment">{t("Past")}</div> */}
            <Collapse
              className="appointmentTodayList"
              collapsible="header"
              items={
                appointmentsByDate?.pastAppointments?.length ? (
                  appointmentsByDate?.pastAppointments?.map(
                    (item: any, index: number) => {
                      return {
                        key: index,
                        label: (
                          <div className="appointmentItem">
                            <div className="date">
                              <div className="info">
                                <span
                                  className="doctorColor"
                                  style={{
                                    background: item.userId.color ?? "#000",
                                  }}
                                ></span>
                                {item.userId.firstName}
                              </div>
                              <label>
                                <CalendarIcon />
                                {format(new Date(item.date), "d-MMM-yy")}{" "}
                                <b>
                                  ({format(new Date(item.startTime), "HH-mm")} -{" "}
                                  {format(new Date(item.endTime), "HH-mm")})
                                </b>
                              </label>

                              <div className="info">{item.patientId?.name}</div>
                            </div>
                            <div className="rightItem">
                              <Dropdown
                                overlay={menu(item)}
                                trigger={["click"]}
                              >
                                <Button onClick={(e) => e.stopPropagation()}>
                                  <MoreOutlined />
                                </Button>
                              </Dropdown>
                            </div>
                          </div>
                        ),
                        children: (
                          <Row
                            className="appointmentItemBody"
                            justify={"space-between"}
                          >
                            <Col>
                              <div>{t("Email")}</div>{" "}
                              <b>{item.patientId?.email}</b>
                            </Col>
                            <Col className="info">
                              <div>{t("Phone Number")} </div>
                              <b>{item.patientId?.phoneNo}</b>
                            </Col>
                            <Col>
                              <div>{t("Notes")}</div>
                              <b>{item?.injury}</b>
                            </Col>
                          </Row>
                        ),
                      };
                    }
                  )
                ) : (
                  <>empty</>
                )
              }
            />
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <div
      className="mainContainer appointmentPage"
      //   style={{ background: `url(${appointmentBg})` }}
    >
      <div className="customDivider">
        <Button type="primary" onClick={showModal}>
          + {t("Make an appointment")}
        </Button>
      </div>
      <Tabs
        defaultActiveKey="1"
        // tabPosition={"left"}
        items={tabItems}
        onChange={onChange}
      />

      {/* Upcoming */}

      {/* End of Upcoming */}
      {/* Past */}

      {/* End of Past */}
      {/* <div className="hide-md">
        <div className="titielAppointment">{t("Today")}</div>
        <Table
          columns={columns}
          dataSource={appointmentsByDate?.todayAppointments ?? []}
        />
      </div>
      <div className="hide-md">
        <div className="titielAppointment">{t("Upcoming")}</div>
        <Table
          columns={columns}
          dataSource={appointmentsByDate?.upcomingAppointments ?? []}
        />
      </div>
      <div className="hide-md">
        <div className="titielAppointment">{t("Past")}</div>
        <Table
          columns={columns}
          dataSource={appointmentsByDate?.pastAppointments ?? []}
        />
      </div> */}

      {/* <div className="show-md appointmentsFullList">
        {appointments?.map((item: any, index: number) => {
          return (
            <div
              key={index}
              className="appointmentsListItem"
              style={{ borderRight: `4px solid ${item?.user?.color}` }}
            >
              <b>
                {format(new Date(item.date), "d-MMM-yy")} - {item?.time}
              </b>
              <div>{item?.patient?.name}</div>
            </div>
          );
        })}
      </div> */}
      <AddApointmentModal
        isOpen={showAppointmentModal}
        onCloseModal={showModal}
        patients={patients}
        staffMembers={staffMembers}
        userData={userData}
        authUser={authUser}
      />
    </div>
  );
};

export default Appointments;
