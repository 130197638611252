import React, { useEffect, useState } from "react";
import { UserOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Row, Timeline } from "antd";
import axios from "axios";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import NotesModal from "../../../Components/Modals/NotesModal/NotesModal";

import "./Timeline.css";
import { BASE_API_URL, constants } from "../../../utils/consts";
import { useTranslation } from "react-i18next";
type Props = {
  staffMembers: any;
  updatedCosts: (totalPaid: any) => void;
  patient?: any;
};
const TimelineContainer: React.FC<Props> = ({
  staffMembers,
  updatedCosts,
  patient,
}) => {
  const params = useParams();
  const { t } = useTranslation();
  const [notes, setNotes] = useState<any>(undefined);
  const [activeNotes, setActiveNotes] = useState<any>(undefined);
  const [isOpenNotesModal, setIsOpenNotesModal] = useState<boolean>(false);
  const [isNotesUsed, setNotesUser] = useState<any>(undefined);

  useEffect(() => {
    // const totalPaidSum = notes.map()
  }, [notes]);
  useEffect(() => {
    axios
      .get(`${BASE_API_URL}/patientNotes/${params.id}`)
      .then((res) => {
        setNotesUser(res.data);
        const notesCount = res.data.notes;
        let sumOfCosts = 0;

        for (let i = 0; i < notesCount.length; i++) {
          if (notesCount[i].costs) {
            sumOfCosts += notesCount[i].costs;
          }
        }
        updatedCosts(sumOfCosts);
        const notesData = res.data.notes
          .sort(
            (a: any, b: any) =>
              (new Date(b.date) as any) - (new Date(a.date) as any)
          )
          .map((item: any) => {
            return {
              color: renderDoctorColor(item.userId),
              children: (
                <div className="timelineInfo">
                  <h4>{item.title}</h4>
                  <p>{format(new Date(item.date), "d-MMM-yyyy hh-mm")}</p>{" "}
                  {/* <p>{formatDistanceToNow(new Date(item.date))} ago</p> */}
                  <p>{item.description}</p>
                  <Row className="patientTimelineCostDetails">
                    <Col>
                      <p>
                        {constants.currency} {item.costs}
                      </p>
                    </Col>
                    <Col>
                      <p>
                        <UserOutlined /> {renderDoctorName(item.userId)}
                      </p>
                    </Col>
                  </Row>
                  <Button
                    size="small"
                    onClick={() => {
                      setActiveNotes(item);
                      setIsOpenNotesModal(!isOpenNotesModal);
                    }}
                  >
                    <EditOutlined />
                  </Button>
                  <Button
                    size="small"
                    onClick={() => {
                      deleteNotes(item);
                      // setIsOpenNotesModal(!isOpenNotesModal)
                    }}
                  >
                    <DeleteOutlined />
                  </Button>
                </div>
              ),
            };
          });
        console.log("notesData", notesData);
        setNotes(notesData);
      })
      .catch((err) => console.error(err));
  }, [isOpenNotesModal, params.id, activeNotes, staffMembers]);

  const renderDoctorName = (user: any) => {
    const findUser = staffMembers.find((item: any) => item._id === user);
    return findUser?.username;
  };

  const renderDoctorColor = (user: any) => {
    const findUser = staffMembers.find((item: any) => item._id === user);
    return findUser?.color ?? "green";
  };

  const deleteNotes = (item: any) => {
    axios
      .delete(`${BASE_API_URL}/patientDetails/notes/${params.id}/${item._id}`)
      .then((res) => {
        axios
          .get(`${BASE_API_URL}/patientNotes/${params.id}`)
          .then((res) => {
            let sumOfCosts = 0;
            const notesCount = res.data.notes;
            for (let i = 0; i < notesCount.length; i++) {
              if (notesCount[i].costs) {
                sumOfCosts += notesCount[i].costs;
              }
            }
            updatedCosts(sumOfCosts);
            const notesData = res.data.notes
              .sort(
                (a: any, b: any) =>
                  (new Date(b.date) as any) - (new Date(a.date) as any)
              )
              .map((item: any) => {
                return {
                  color: "green",
                  children: (
                    <div className="timelineInfo">
                      <h4>{item.title}</h4>
                      <p>{format(new Date(item.date), "d-MMM-yyyy hh-mm")}</p>
                      <p>{item.description}</p>
                      <Row className="patientTimelineCostDetails">
                        <Col>
                          <p>
                            {constants.currency} {item.costs}
                          </p>
                        </Col>
                        <Col>
                          <p>
                            <UserOutlined /> {renderDoctorName(item.userId)}
                          </p>
                        </Col>
                      </Row>

                      <Button
                        size="small"
                        onClick={() => {
                          setActiveNotes(item);
                          setIsOpenNotesModal(!isOpenNotesModal);
                        }}
                      >
                        Edit
                      </Button>
                      <Button
                        size="small"
                        onClick={() => {
                          deleteNotes(item);
                          // setIsOpenNotesModal(!isOpenNotesModal)
                        }}
                      >
                        Delete
                      </Button>
                    </div>
                  ),
                };
              });
            setNotes(notesData);
          })
          .catch((err) => console.error(err));
      })
      .catch((err) => console.error(err));
  };
  const isNotesModalOpen = () => {
    setIsOpenNotesModal(!isOpenNotesModal);
    setActiveNotes(undefined);
  };

  console.log("notes", notes);
  return (
    <>
      <Button onClick={isNotesModalOpen}>+ {t("Add Notes")}</Button>
      <Divider />
      <Timeline items={notes} />
      <NotesModal
        isOpen={isOpenNotesModal}
        notes={notes}
        onCloseModal={isNotesModalOpen}
        staffMembers={staffMembers}
        activeNotes={activeNotes}
        isNotesUsed={isNotesUsed}
        patient={patient}
      />
    </>
  );
};
export default TimelineContainer;
