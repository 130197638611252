import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Space,
  Table,
  Tabs,
  TabsProps,
  Tag,
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TimelineContainer from "./Components/Timeline";
import "./PatientProfile.css";
import AddPatientCostsModal from "../../Components/Modals/AddPatientCosts/AddPatientCosts";
import PatientStatistic from "../../Components/PatientStatistic/PatientStatistic";
import type { ColumnsType } from "antd/es/table";
import { BASE_API_URL, constants } from "../../utils/consts";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import UploadPatientPhotos from "./UploadPhotos/UploadPhotos";
import { setLoader } from "../../utils/redux/slices/loaderSlice";
import { Select } from "antd";
import mAvatar from "../../assets/m-avatar.png";
import wAvatar from "../../assets/w-avatar.png";
type Props = {};
const { Option } = Select;

interface FieldData {
  name: string | number | (string | number)[];
  value?: any;
  touched?: boolean;
  validating?: boolean;
  errors?: string[];
}
interface DataType {
  key: string;
  name: string;
  age: number;
  address: string;
  tags: string[];
}

const PatiendProfile: React.FC<Props> = () => {
  const { t } = useTranslation();
  const params = useParams();
  const dispatch = useDispatch();
  const [patientCostsModal, setPatientCostsModal] = useState<boolean>(false);
  const [totalPaid, setTotalPaid] = useState<any>(0);
  const [needToPaid, setNeedToPaid] = useState<any>(0);
  const [totalSum, setTotalSum] = useState<any>(0);
  const [staffMembers, setStaffMembers] = useState<any>(undefined);
  const [patient, setPatient] = useState<any>(undefined);
  const [billing, setBilling] = useState<any>(undefined);
  const userData = useSelector((state: any) => state.userData.user);
  const [form] = Form.useForm(); // Create a form instance using Form.useForm()

  const columns: ColumnsType<DataType> = [
    {
      title: t("Costs"),
      dataIndex: "charges",
      key: "charges",
      render: (text) => (
        <>
          {text} {constants.currency}
        </>
      ),
    },
    {
      title: t("Date"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value, row) => {
        return format(new Date(value), "d-MMM-yyyy");
      },
    },
    {
      title: t("Action"),
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          {/* <a>Invite {record.name}</a> */}
          {/* <a>Delete</a> */}
        </Space>
      ),
    },
  ];
  useEffect(() => {
    if (userData.clientId) {
      dispatch(setLoader(true));
      axios
        .get(`${BASE_API_URL}/${userData.clientId}/users`)
        .then((res) => {
          setStaffMembers(res.data);
        })
        .catch((err) => console.error(err))
        .finally(() => dispatch(setLoader(false)));
    }
  }, [userData]);
  useEffect(() => {
    axios
      .get(`${BASE_API_URL}/${params.id}/billings`)
      .then((res) => {
        setBilling(res.data);
        const totalCharges = res.data.reduce(
          (total: any, billing: any) => total + billing.charges,
          0
        );
        setTotalSum(totalCharges);
        setNeedToPaid(totalCharges - totalPaid);
      })
      .catch((err) => console.error(err));
  }, [totalPaid, params.id]);
  useEffect(() => {
    axios
      .get(`${BASE_API_URL}/patient/${params.id}`)
      .then((res) => {
        setPatient(res.data);
      })
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    // Set form initial values when activeNotes changes
    if (patient && totalSum) {
      form.setFieldsValue({
        costs: totalSum,
      });
    } else {
      // Reset form fields if activeNotes is null or undefined
      form.resetFields();
    }
  }, [patient, form, totalSum]);

  const onFinish = async (values: any) => {
    axios
      .put(`${BASE_API_URL}/patient/${params.id}`, values)
      .then((res) => {
        axios
          .get(`${BASE_API_URL}/patient/${params.id}`)
          .then((res) => {
            setPatient(res.data);
          })
          .catch((err) => console.error(err));
      })
      .catch((err) => console.error(err));
  };

  const onFinishFailed = (errorInfo: any) => {
    console.error("Failed:", errorInfo);
  };

  const hidePatientCostModal = () => {
    setPatientCostsModal(!patientCostsModal);
  };

  const onUpdateTimelineCosts = (costs: any) => {
    setTotalPaid(costs);
    setNeedToPaid(totalSum - costs);
  };

  const onAddPationCosts = (costs: any) => {
    if (userData) {
      axios
        .post(`${BASE_API_URL}/billing/create`, {
          userId: userData.id,
          patientId: params.id,
          name: patient?.name ?? "",
          doctorName: userData.username,
          charges: costs,
        })
        .then((res) => {
          axios
            .get(`${BASE_API_URL}/${params.id}/billings`)
            .then((res) => {
              // setPatient(res.data)
              setBilling(res.data);
              setPatientCostsModal(!patientCostsModal);
              const totalCharges = res.data.reduce(
                (total: any, billing: any) => total + billing.charges,
                0
              );
              setTotalSum(totalCharges);
              setNeedToPaid(totalCharges - totalPaid);
              form.resetFields();
            })
            .catch((err) => console.error(err));
        })
        .catch((err) => console.error(err));
    }
  };

  const onUploadPhotos = (values: any) => {
    const formData = new FormData();
    formData.append("file", values.file[0].originFileObj);
    axios
      .put(`${BASE_API_URL}/patient/${params.id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setPatient(res.data.patient);
      })
      .catch((error) => {
        console.error("Error during upload:", error);
      });
  };

  const onChange = (key: string) => {
    console.log(key);
  };

  const items: TabsProps["items"] = [
    {
      key: t("Total Costs"),
      label: t("Total Costs"),
      children: (
        <Row>
          <Col xs={24}>
            <Table columns={columns} dataSource={billing} />
            <Button onClick={hidePatientCostModal}>+ {t("Add Costs")}</Button>
          </Col>
        </Row>
      ),
    },
    {
      key: t("Photo"),
      label: t("Photo"),
      children: (
        <Row>
          <Col xs={24}>
            {t("Uploaded photos")}
            <UploadPatientPhotos
              onUploadPhotos={onUploadPhotos}
              patient={patient}
            />
          </Col>
        </Row>
      ),
    },
  ];

  if (!patient) {
    return <></>;
  }
  return (
    <>
      <div className="profileHeader">
        <div className="userData">
          <div className="userPhoto">
            {patient?.user?.avatar?.url ? (
              <img src={patient?.user?.avatar?.url} alt="W Profile" />
            ) : patient?.gender === "male" ? (
              <img src={mAvatar} alt="M Profile" />
            ) : patient?.gender === "female" ? (
              <img src={wAvatar} alt="W Profile" />
            ) : undefined}
            {/* <button onClick={() => openFileStackDialog()}>
              <CameraOutlined />
            </button> */}
          </div>
          <div className="userInfo">
            <div className="name">{patient?.name}</div>
            {/* <div className="position">
              <div className="info">
                <span>
                  <Tag
                    color={userDetails?.user?.color}
                    key={userDetails?.user?.position}
                  >
                    {t(userDetails?.user?.position)}
                  </Tag>
                </span>
              </div>
            </div> */}
            <div className="gender">{t(patient?.gender)}</div>
          </div>
        </div>
      </div>
      <Row>
        <Col span={12}>
          <div className="childBodyContainer">
            <h3>{t("User Info")}</h3>
            <Form
              name="global_state"
              className="patientForm"
              // fields={fields}
              form={form}
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              // onFieldsChange={(_, allFields) => {
              //   onChange(allFields);
              // }}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="name"
                    initialValue={patient?.name ?? ""}
                    label={t("Name")}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="email"
                    initialValue={patient?.email ?? ""}
                    label="Email"
                    //   rules={[{ required: true, message: 'Username is required!' }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="gender"
                    initialValue={patient?.gender ?? ""}
                    label={t("Gender")}
                  >
                    <Select placeholder={t("Select Gender")} allowClear>
                      <Option value="male">{t("Male")}</Option>
                      <Option value="female">{t("Female")}</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="jmbg"
                    initialValue={patient?.jmbg ?? ""}
                    label={t("Social Number")}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="address"
                    initialValue={patient?.address ?? ""}
                    label={t("Address")}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="phoneNo"
                    initialValue={patient?.phoneNo ?? ""}
                    label={t("Phone Number")}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item name="costs" label={t("Total Costs")}>
                <Input disabled={true} />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 0, span: 16 }}>
                <Button type="primary" htmlType="submit">
                  {t("Save")}
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div className="childBodyContainer">
            <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
          </div>
          <AddPatientCostsModal
            isOpen={patientCostsModal}
            onCloseModal={hidePatientCostModal}
            onUpdateCosts={onAddPationCosts}
          />
        </Col>
        <Col span={12} className="profileTimeline">
          <PatientStatistic totalPaid={totalPaid} needToPaid={needToPaid} />
          {/* <Divider /> */}
          <div className="childBodyContainer">
            <TimelineContainer
              staffMembers={staffMembers}
              updatedCosts={onUpdateTimelineCosts}
              patient={patient}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};
export default PatiendProfile;
