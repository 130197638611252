import React, { useEffect, useRef, useState, useTransition } from "react";
import { DayPilot, DayPilotCalendar } from "@daypilot/daypilot-lite-react";
import { LeftOutlined, RightOutlined, BellOutlined } from "@ant-design/icons";
import { format, getISOWeek, getYear, subHours } from "date-fns";
import { useTranslation } from "react-i18next";
import "./cal.scss";
type Props = {
  appointments: any;
  openModal: (data: any, mode: string) => void;
  onUpdateDate: (data: any) => void;
  activeDate: (date: any) => void;
};

const eventIcon = `<svg fill="#000000" height="20px" width="20px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
viewBox="0 0 611.999 611.999" xml:space="preserve">
<g>
<g>
   <g>
	   <path d="M570.107,500.254c-65.037-29.371-67.511-155.441-67.559-158.622v-84.578c0-81.402-49.742-151.399-120.427-181.203
		   C381.969,34,347.883,0,306.001,0c-41.883,0-75.968,34.002-76.121,75.849c-70.682,29.804-120.425,99.801-120.425,181.203v84.578
		   c-0.046,3.181-2.522,129.251-67.561,158.622c-7.409,3.347-11.481,11.412-9.768,19.36c1.711,7.949,8.74,13.626,16.871,13.626
		   h164.88c3.38,18.594,12.172,35.892,25.619,49.903c17.86,18.608,41.479,28.856,66.502,28.856
		   c25.025,0,48.644-10.248,66.502-28.856c13.449-14.012,22.241-31.311,25.619-49.903h164.88c8.131,0,15.159-5.676,16.872-13.626
		   C581.586,511.664,577.516,503.6,570.107,500.254z M484.434,439.859c6.837,20.728,16.518,41.544,30.246,58.866H97.32
		   c13.726-17.32,23.407-38.135,30.244-58.866H484.434z M306.001,34.515c18.945,0,34.963,12.73,39.975,30.082
		   c-12.912-2.678-26.282-4.09-39.975-4.09s-27.063,1.411-39.975,4.09C271.039,47.246,287.057,34.515,306.001,34.515z
			M143.97,341.736v-84.685c0-89.343,72.686-162.029,162.031-162.029s162.031,72.686,162.031,162.029v84.826
		   c0.023,2.596,0.427,29.879,7.303,63.465H136.663C143.543,371.724,143.949,344.393,143.97,341.736z M306.001,577.485
		   c-26.341,0-49.33-18.992-56.709-44.246h113.416C355.329,558.493,332.344,577.485,306.001,577.485z"/>
	   <path d="M306.001,119.235c-74.25,0-134.657,60.405-134.657,134.654c0,9.531,7.727,17.258,17.258,17.258
		   c9.531,0,17.258-7.727,17.258-17.258c0-55.217,44.923-100.139,100.142-100.139c9.531,0,17.258-7.727,17.258-17.258
		   C323.259,126.96,315.532,119.235,306.001,119.235z"/>
   </g>
</g>
</g>
</svg>`;

const appointmentIcon = `<svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3 10H21M7 3V5M17 3V5M6.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4802 21 18.9201 21 17.8V8.2C21 7.07989 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.07989 3 8.2V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;
const CalendarWeek: React.FC<Props> = ({
  appointments,
  openModal,
  onUpdateDate,
  activeDate,
}) => {
  const [dayPilotEvents, setDayPaylotEvents] = useState<any>(undefined);
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(new Date());
  const calendarRef: any = useRef();
  const [config, setConfig] = useState({
    viewType: "Week",
    // timeRangeSelectedHandling: "Enabled",
    timeFormat: "Clock24Hours",
    eventMoveHandling: "Disabled",
    onTimeRangeSelected: async (args: any) => {
      //   const modal = await DayPilot.Modal.prompt(
      //     "Create a new event:",
      //     "Event 1"
      //   );
      //   const dp = args.control;
      //   dp.clearSelection();
      //   if (modal.canceled) {
      //     return;
      //   }
      //   dp.events.add({
      //     start: args.start,
      //     end: args.end,
      //     id: DayPilot.guid(),
      //     text: modal.result,
      //   });
      openModal(args, "create");
    },
    // eventClickHandling: "Edit",
    onEventClick: (args: any) => {
      openModal(args.e.data, "edit");
      if (args.e.tag("disabled")) {
        args.preventDefault();
      }
    },
    // eventMoveHandling: "Update",
    onEventMoved: (args: any) => {
      onUpdateDate(args.e.data);
    },
    eventResizeHandling: "Update",
    onEventResized: (args: any) => {
      //   console.log("Event resized: " + args.e.text());
    },
    // eventClickHandling: "Disabled",
  });

  useEffect(() => {
    if (appointments) {
      const appointmentDates = appointments?.map((item: any, index: number) => {
        console.log("itemitemitem", item);
        return {
          id: index,
          text: item?.organizerId?.firstName ?? "",
          user: item?.userId ?? item?.userId._id,
          patient: item?.patientId ?? item?.patientId?._id,
          start: new Date(item.startTime ?? new Date()),
          end: new Date(item.endTime ?? new Date()),
          resource: `R${index}`,
          appointmentId: item._id,
          type: item.type,
          barColor: item?.userId?.color,
          barBackColor: item?.userId?.color,
          injury: item?.injury,
          html: `<div style="color: black;">
		  <div class="iconBody">${
        item.type === "EVENT" ? eventIcon : appointmentIcon
      }</div>
		 <div>${item?.patientId?.name ?? ""}</div>
		 <div>${format(new Date(item?.startTime ?? new Date()), "dd-MM-yyyy")}</div> 
		 <div>${format(
       subHours(new Date(item?.startTime ?? new Date()), 2),
       "HH:mm"
     )} - ${format(
            subHours(new Date(item?.endTime ?? new Date()), 2),
            "HH:mm"
          )}</div>
		  </div>`,
        };
      });
      setDayPaylotEvents(appointmentDates);
      setConfig((prevConfig) => ({
        ...prevConfig,
        events: appointmentDates,
      }));
    }
  }, [appointments]);

  //   useEffect(() => {
  //     const events = [
  //       {
  //         id: 1,
  //         text: "Event 1",
  //         start: DayPilot.Date.today().addHours(10),
  //         end: DayPilot.Date.today().addHours(12),
  //         resource: "R1",
  //       },
  //       {
  //         id: 2,
  //         text: "Event 2",
  //         start: "2024-01-22T10:00:00",
  //         end: "2024-01-22T11:00:00",
  //         resource: "R2",
  //         barColor: "red",
  //         barBackColor: "green",
  //       },
  //     ];
  //     console.log("dddd", events);
  //     // load event data using config
  //     setConfig((prevConfig) => ({
  //       ...prevConfig,
  //       events,
  //     }));

  //     // to improve performance, you can use the direct API to load resources and events instead:
  //     // getCalendar().update({events, columns});
  //   }, []);

  const getCalendar = () => calendarRef.current?.control;

  const crudMenu = new DayPilot.Menu({
    items: [
      {
        text: "edit",
        // onClick: (args: any) => console.log("edit"),
      },
      {
        text: "delete",
        // onClick: (args: any) => console.log("delete"),
      },
      { text: "-" },
    ],
  });
  const onBeforeEventRender = (args: any): void => {
    const status = args.data.status;
    args.data.cssClass = "shift";
    // args.data.backColor = "#5bc0de"; // Event background color
    // args.data.fontColor = "#ffffff"; // Event text color
    args.data.contextMenu = crudMenu;
    // if (isShift(status)) {
    // 	args.data.contextMenu = crudMenu;
    // } else if (isLeavePending(status) || isLeaveApproved(status)) {
    // 	args.data.contextMenu = crudMenuLeave;
    // }
  };

  const handlePrevButtonClick = () => {
    setStartDate((prevStartDate) => {
      const newStartDate = new Date(prevStartDate);
      newStartDate.setDate(newStartDate.getDate() - 7);
      activeDate(newStartDate);
      return newStartDate;
    });
  };

  const handleNextButtonClick = () => {
    setStartDate((prevStartDate) => {
      const newStartDate = new Date(prevStartDate);
      newStartDate.setDate(newStartDate.getDate() + 7);
      activeDate(newStartDate);
      return newStartDate;
    });
  };

  const getActiveDate = () => {
    const weekNumber = getISOWeek(new Date(startDate));
    const year = getYear(new Date(startDate));
    return `${t("Week")}: ${weekNumber} / ${year}`;
  };
  return (
    <div>
      <div className="selectWeek">
        <button onClick={handlePrevButtonClick}>
          <LeftOutlined />
        </button>
        <div className="activeWeek">{getActiveDate()}</div>
        <button onClick={handleNextButtonClick}>
          <RightOutlined />
        </button>
      </div>
      <DayPilotCalendar
        {...config}
        ref={calendarRef}
        startDate={startDate}
        businessBeginsHour={8}
        businessEndsHour={17}
        // businessWeekends={true}
        // cellsMarkBusiness={false}
        onBeforeEventRender={onBeforeEventRender}
        businessOnly={true}
      />
    </div>
  );
};
export default CalendarWeek;
